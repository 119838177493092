import React from "react"
import Icon from "../UI/Icon"

const DepartureIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M28.9 1.88344C24 0.966774 19.5166 3.13344 17.0333 6.75011C17.05 6.73344 17.05 6.71677 17.0666 6.70011C16.4 6.66677 15.7 6.66677 15 6.66677C7.63329 6.66677 1.66663 7.50011 1.66663 13.3334V30.0001C1.66663 31.4668 2.31663 32.7834 3.33329 33.7001V38.3334H8.33329V35.0001H21.6666V38.3334H26.6666V33.7001C27.6833 32.7834 28.3333 31.4668 28.3333 30.0001V24.8668C34.5333 23.9668 39.1666 18.2334 38.2 11.5834C37.5 6.75011 33.7 2.76677 28.9 1.88344V1.88344ZM7.49996 31.6668C6.11663 31.6668 4.99996 30.5501 4.99996 29.1668C4.99996 27.7834 6.11663 26.6668 7.49996 26.6668C8.88329 26.6668 9.99996 27.7834 9.99996 29.1668C9.99996 30.5501 8.88329 31.6668 7.49996 31.6668ZM4.99996 21.6668V13.3334H15C15 16.6001 16.35 19.5501 18.5166 21.6668H4.99996ZM22.5 31.6668C21.1166 31.6668 20 30.5501 20 29.1668C20 27.7834 21.1166 26.6668 22.5 26.6668C23.8833 26.6668 25 27.7834 25 29.1668C25 30.5501 23.8833 31.6668 22.5 31.6668ZM26.6666 21.6668C22.0666 21.6668 18.3333 17.9334 18.3333 13.3334C18.3333 8.73344 22.0666 5.00011 26.6666 5.00011C31.2666 5.00011 35 8.73344 35 13.3334C35 17.9334 31.2666 21.6668 26.6666 21.6668ZM27.5 6.66677H25V15.0001L31.0333 18.6001L32.2833 16.5501L27.5 13.7501V6.66677Z" />
    </Icon>
  )
}

export default DepartureIcon
