import React from "react"
import Icon from "../UI/Icon"

const AfterSalesIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M33.9334 18.3333H31.6667V8.33325H21.6667V6.06658C21.6667 3.88325 20.1 1.89992 17.9334 1.68325C15.4334 1.43325 13.3334 3.38325 13.3334 5.83325V8.33325H3.35004V17.9999H5.66671C7.85004 17.9999 9.83337 19.4666 10.25 21.5999C10.8 24.4666 8.61671 26.9999 5.83337 26.9999H3.33337V36.6666H13V34.3333C13 32.1499 14.4667 30.1666 16.6 29.7499C19.4667 29.1999 22 31.3833 22 34.1666V36.6666H31.6667V26.6666H34.1667C36.6167 26.6666 38.5667 24.5666 38.3167 22.0666C38.1 19.8999 36.1 18.3333 33.9334 18.3333V18.3333Z" />
    </Icon>
  )
}

export default AfterSalesIcon
