import * as React from "react"
import Hero from "../components/Home/Hero"
import Products from "../components/Home/Products"
import Services from "../components/Home/Services"
import Seo from "../components/Layout/Seo"
import Site from "../components/Layout/Site"
import HeavyLifting from "../components/Shared/HeavyLifting"
// markup
const IndexPage = () => {
  return (
    <Site home>
      <Seo title="Home" />
      <Hero />
      <Products />
      <Services />
      <HeavyLifting />
    </Site>
  )
}

export default IndexPage
