import React from "react"
import Icon from "../UI/Icon"

const AgricultureIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M32.4999 20C34.0499 20 35.4666 20.4666 36.6666 21.2666V13.3333C36.6666 11.5 35.1666 9.99995 33.3332 9.99995H22.8499L21.0832 8.23328L23.4332 5.88328L22.2499 4.69995L16.3666 10.5833L17.5499 11.7666L19.8999 9.41662L21.6666 11.1833V15C21.6666 16.8333 20.1666 18.3333 18.3332 18.3333H17.4332C19.0166 20.1 19.9999 22.4333 19.9999 25C19.9999 25.5666 19.9332 26.1166 19.8499 26.6666H25.0832C25.4999 22.9166 28.6499 20 32.4999 20Z" />
      <path d="M32.5 21.6665C29.2833 21.6665 26.6666 24.2832 26.6666 27.4998C26.6666 30.7165 29.2833 33.3332 32.5 33.3332C35.7166 33.3332 38.3333 30.7165 38.3333 27.4998C38.3333 24.2832 35.7166 21.6665 32.5 21.6665ZM32.5 29.9998C31.1166 29.9998 30 28.8832 30 27.4998C30 26.1165 31.1166 24.9998 32.5 24.9998C33.8833 24.9998 35 26.1165 35 27.4998C35 28.8832 33.8833 29.9998 32.5 29.9998Z" />
      <path d="M6.66667 14.9998H15C15 13.1665 13.5 11.6665 11.6667 11.6665H6.66667C5.75 11.6665 5 12.4165 5 13.3332C5 14.2498 5.75 14.9998 6.66667 14.9998Z" />
      <path d="M16.3833 23.0332L16.0833 22.2498L17.6333 21.6665C16.8666 19.8998 15.5 18.4832 13.7833 17.6165L13.1166 19.0998L12.35 18.7498L13.0166 17.2498C12.1 16.8832 11.0666 16.6665 9.99996 16.6665C9.11663 16.6665 8.26663 16.8498 7.46663 17.0998L8.03329 18.6165L7.24996 18.9165L6.66663 17.3665C4.89996 18.1332 3.48329 19.4998 2.61663 21.2165L4.09996 21.8832L3.74996 22.6498L2.24996 21.9832C1.88329 22.8998 1.66663 23.9332 1.66663 24.9998C1.66663 25.8832 1.84996 26.7332 2.09996 27.5332L3.61663 26.9665L3.91663 27.7498L2.36663 28.3332C3.13329 30.0998 4.49996 31.5165 6.21663 32.3832L6.88329 30.8998L7.64996 31.2498L6.98329 32.7498C7.89996 33.1165 8.93329 33.3332 9.99996 33.3332C10.8833 33.3332 11.7333 33.1498 12.5333 32.8998L11.9666 31.3832L12.75 31.0832L13.3333 32.6332C15.1 31.8665 16.5166 30.4998 17.3833 28.7832L15.9 28.1165L16.25 27.3498L17.75 28.0165C18.1166 27.0998 18.3333 26.0665 18.3333 24.9998C18.3333 24.1165 18.15 23.2665 17.9 22.4665L16.3833 23.0332ZM11.9166 29.6165C9.36663 30.6665 6.43329 29.4665 5.38329 26.9165C4.33329 24.3665 5.53329 21.4332 8.08329 20.3832C10.6333 19.3332 13.5666 20.5332 14.6166 23.0832C15.6833 25.6332 14.4666 28.5665 11.9166 29.6165Z" />
    </Icon>
  )
}

export default AgricultureIcon
