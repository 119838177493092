import axios from "axios"
import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import ArrowRightIcon from "../Icons/ArrowRightIcon"
import Button from "../UI/Button"
import Element from "../UI/Element"
import { Col, Container, Row } from "../UI/Grid"
import ScaledElement from "../UI/ScaledElement"
import { Section } from "../UI/Section"
import { Heading, Paragraph } from "../UI/Typography"
import { media } from "styled-bootstrap-grid"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import ScaledImage from "../UI/ScaledImage"

const Products = () => {
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState(null)

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    axios
      .get(
        `${
          isBrowser &&
          window?.location?.origin === "https://boeingmhc.digiteer.dev"
            ? process.env.GATSBY_STAGING_API_URL
            : process.env.GATSBY_API_URL
        }/featured-subcategories`
      )
      .then((res) => {
        setProducts(res.data)
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      {products.map((p, idx) => {
        const odd = idx % 2 === 1
        return (
          <Section>
            <SectionImage odd={odd}>
              <ImageHolder>
                <img src={p.thumbnail} alt={p.name} />
              </ImageHolder>

              {p.logo && (
                <Logo>
                  <img src={p.logo} alt="logo" />
                </Logo>
              )}
            </SectionImage>
            <Container>
              <Row justifyContent={!odd && "flex-end"}>
                <Col col={{ lg: 5 }}>
                  <Heading fs="h2" color="text">
                    {p.name}
                  </Heading>
                  {p.description && (
                    <Paragraph mt={6}>{p.description}</Paragraph>
                  )}
                  <Button
                    variant="brand"
                    size="lg"
                    as={Link}
                    mt={6}
                    to={`/subcategories/${p.slug}`}
                  >
                    <Element
                      as="span"
                      mr={4}
                    >{`View ${p.name} Products`}</Element>
                    <ArrowRightIcon />
                  </Button>

                  {p.products.length > 0 && (
                    <Element mt={6}>
                      <Swiper slidesPerView={3} spaceBetween={16}>
                        {p.products.map((p) => (
                          <SwiperSlide key={p.id}>
                            <ProductThumb to={`/products/${p.slug}`}>
                              <ScaledImage scaleHeight={1} scaleWidth={1}>
                                <img src={p.thumbnail} alt={p.name} />
                              </ScaledImage>
                            </ProductThumb>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Element>
                  )}
                </Col>
              </Row>
            </Container>
          </Section>
        )
      })}
    </>
  )
}

const SectionImage = styled.div`
  position: relative;
  top: 0;
  height: 100%;
  background: #fafafa;
  /* display: flex;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  } */

  ${(p) =>
    p.odd
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  ${media.lg`
    position: absolute;
    width: 50%;
  `}
`

const Logo = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;

  img {
    max-width: 100%;
    height: 50px;
  }
`

const ProductThumb = styled(Link)`
  display: block;
  border: 1px solid transparent;
  margin-bottom: 6px;
  transition: var(--transition);

  &:hover {
    border-color: var(--brand);
    box-shadow: 6px 6px 0px rgba(84, 85, 181, 0.25);
  }
`

const ImageHolder = styled.div`
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  margin-bottom: 32px;

  ${media.lg`
    padding-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      pointer-events: none;
       margin-bottom: 0;
  `}

  iframe,
  .gatsby-image-wrapper,
  picture,
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export default Products
