import React from "react"
import Icon from "../UI/Icon"

const SkilledInstallersIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M15.5 25C11.05 25 2.16663 27.2333 2.16663 31.6667V35H28.8333V31.6667C28.8333 27.2333 19.95 25 15.5 25Z" />
      <path d="M23.4166 13.3334H22.1666C22.1666 10.8667 20.8166 8.75008 18.8333 7.58342V9.16675C18.8333 9.63341 18.4666 10.0001 17.9999 10.0001C17.5333 10.0001 17.1666 9.63341 17.1666 9.16675V6.90008C16.6333 6.76675 16.0833 6.66675 15.4999 6.66675C14.9166 6.66675 14.3666 6.76675 13.8333 6.90008V9.16675C13.8333 9.63341 13.4666 10.0001 12.9999 10.0001C12.5333 10.0001 12.1666 9.63341 12.1666 9.16675V7.58342C10.1833 8.75008 8.83325 10.8667 8.83325 13.3334H7.58325V15.0001H23.4166V13.3334Z" />
      <path d="M15.5 21.6667C18.6 21.6667 21.1833 19.5334 21.9333 16.6667H9.06665C9.81665 19.5334 12.4 21.6667 15.5 21.6667Z" />
      <path d="M37.1333 10.3833L38.6833 9L37.4333 6.83333L35.45 7.48333C35.2166 7.3 34.95 7.15 34.6667 7.03333L34.25 5H31.75L31.3333 7.03333C31.05 7.15 30.7833 7.3 30.5333 7.48333L28.5667 6.83333L27.3167 9L28.8667 10.3833C28.8333 10.6667 28.8333 10.9667 28.8667 11.25L27.3167 12.6667L28.5667 14.8333L30.5667 14.2C30.7833 14.3667 31.0333 14.5 31.2833 14.6167L31.75 16.6667H34.25L34.7 14.6333C34.9667 14.5167 35.2 14.3833 35.4333 14.2167L37.4166 14.85L38.6667 12.6833L37.1166 11.2667C37.1666 10.95 37.15 10.6667 37.1333 10.3833ZM33 12.9167C31.85 12.9167 30.9167 11.9833 30.9167 10.8333C30.9167 9.68333 31.85 8.75 33 8.75C34.15 8.75 35.0833 9.68333 35.0833 10.8333C35.0833 11.9833 34.15 12.9167 33 12.9167Z" />
      <path d="M32.8332 17.9834L31.4166 18.4501C31.2499 18.3167 31.0666 18.2167 30.8666 18.1334L30.5666 16.6667H28.7832L28.4832 18.1167C28.2832 18.2001 28.0832 18.3167 27.9166 18.4334L26.5166 17.9667L25.6166 19.5167L26.7166 20.5001C26.6999 20.7167 26.6999 20.9167 26.7166 21.1167L25.6166 22.1334L26.5166 23.6834L27.9499 23.2334C28.1166 23.3501 28.2832 23.4501 28.4666 23.5334L28.7666 25.0001H30.5499L30.8666 23.5501C31.0499 23.4667 31.2332 23.3667 31.3999 23.2501L32.8166 23.7001L33.7166 22.1501L32.6166 21.1334C32.6332 20.9167 32.6332 20.7167 32.6166 20.5167L33.7166 19.5334L32.8332 17.9834ZM29.6666 22.3167C28.8499 22.3167 28.1832 21.6501 28.1832 20.8334C28.1832 20.0167 28.8499 19.3501 29.6666 19.3501C30.4832 19.3501 31.1499 20.0167 31.1499 20.8334C31.1499 21.6501 30.4832 22.3167 29.6666 22.3167Z" />
    </Icon>
  )
}

export default SkilledInstallersIcon
