import React from "react"
import Icon from "../UI/Icon"

const TruckIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M37.9534 30.5218L34.9558 30.5205L34.9597 7.88618C34.9598 6.78646 34.0685 5.89463 32.9688 5.8945C32.9686 5.8945 32.9685 5.8945 32.9684 5.8945C31.8688 5.8945 30.9772 6.7858 30.9771 7.88539L30.9748 20.9953H26.8234V13.4614C26.8234 12.3617 25.9318 11.4701 24.8321 11.4701H24.3291V9.56815C24.3291 8.86642 23.7603 8.29771 23.0587 8.29771H21.6182C20.9165 8.29771 20.3477 8.86656 20.3477 9.56815V11.4702H19.9135L15.9016 3.00714C15.4696 2.09606 14.5516 1.51513 13.5433 1.515L1.99354 1.51367H1.99328C0.893556 1.51367 0.00212413 2.4055 0.00199138 3.50509C-0.000265409 20.531 9.60938e-08 10.8557 9.60938e-08 25.7554C1.88681 23.7172 4.52447 22.5557 7.30417 22.5557C9.43657 22.5557 11.5058 23.2415 13.2116 24.5007C14.9175 23.2414 16.9867 22.5557 19.1191 22.5557C22.1107 22.5557 24.9329 23.9054 26.8233 26.2133V24.978H30.974L30.9727 32.5105C30.9726 33.6101 31.8636 34.5017 32.9631 34.5022L38.0077 34.5043C38.0081 34.5043 38.0083 34.5043 38.0086 34.5043C39.14 34.5043 40.0512 33.5606 39.9976 32.4172C39.9474 31.3464 39.0253 30.5223 37.9534 30.5218ZM3.98297 11.4702L3.98403 5.49664L12.7457 5.49797L15.2669 11.4702H3.98297Z" />
      <path d="M19.1191 26.5381C16.141 26.5381 13.6438 28.7419 13.2116 31.6237C12.7767 28.7236 10.2676 26.5381 7.30417 26.5381C4.0139 26.5381 1.33031 29.214 1.33031 32.5119C1.33031 35.8062 4.01018 38.4858 7.30417 38.4858C10.2517 38.4858 12.7746 36.3154 13.2116 33.4002C13.6439 36.2825 16.1415 38.4858 19.1191 38.4858C22.3949 38.4858 25.093 35.8272 25.093 32.5119C25.093 29.2047 22.4017 26.5381 19.1191 26.5381ZM7.30417 34.5032C6.20445 34.5032 5.31288 33.6117 5.31288 32.5119C5.31288 31.4122 6.20445 30.5207 7.30417 30.5207C8.40389 30.5207 9.29545 31.4122 9.29545 32.5119C9.29545 33.6117 8.40402 34.5032 7.30417 34.5032ZM19.1191 34.5032C18.0194 34.5032 17.1278 33.6117 17.1278 32.5119C17.1278 31.4122 18.0194 30.5207 19.1191 30.5207C20.2188 30.5207 21.1104 31.4122 21.1104 32.5119C21.1104 33.6117 20.219 34.5032 19.1191 34.5032Z" />
    </Icon>
  )
}

export default TruckIcon
