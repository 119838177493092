import React from "react"
import Icon from "../UI/Icon"

const PlumbingIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M26.9332 9.39992L32.8332 15.2999C34.7832 13.3499 34.7832 10.1833 32.8332 8.23325L26.9332 2.33325L19.8666 9.39992L23.3999 12.9333L26.9332 9.39992Z" />
      <path d="M13.9623 15.2877L8.06982 21.1802L11.6053 24.7157L17.4978 18.8232L13.9623 15.2877Z" />
      <path d="M25.75 12.9332L23.4 15.2832L16.3166 8.21655L12.7833 11.7499L19.85 18.8166L5.69995 32.9666L10.4166 37.6832L28.1 19.9999L29.2833 21.1832L31.6333 18.8332L25.75 12.9332Z" />
    </Icon>
  )
}

export default PlumbingIcon
