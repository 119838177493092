import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import "swiper/css"
import ScaledElement from "../UI/ScaledElement"
import { Col, Row, Container } from "../UI/Grid"
import ScaledImage from "../UI/ScaledImage"
import { StaticImage } from "gatsby-plugin-image"
import { Heading, Paragraph } from "../UI/Typography"
import Button from "../UI/Button"
import { Link } from "gatsby"
import Element from "../UI/Element"
import ArrowRightIcon from "../Icons/ArrowRightIcon"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"

const Hero = () => {
  return (
    <HeroStyled>
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        autoplay={{ delay: 5000 }}
      >
        <SwiperSlide>
          <ScaledElement scaleWidth={1440} scaleHeight={754} fullWidth>
            <ScaledImage bg>
              <StaticImage src="../../images/slider-1.jpg" alt="slider" />
            </ScaledImage>
            <Container py={20}>
              <Row color="#fff">
                <Col col={{ md: 5 }}>
                  <Heading fs="display-4" color="#fff" mb={4}>
                    The Future of Commerce is in Your Hands
                  </Heading>
                  <Paragraph color="#fff" mb={4}>
                    Boeing MHC thrusts to exceed clientele expectations by
                    accommodating tailor-fit solutions for every industry.
                  </Paragraph>
                  <Button variant="white" as={Link} to="/contact" size="lg">
                    <Element
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <Element as="span" mr={10}>
                        Get a free quote
                      </Element>
                      <ArrowRightIcon />
                    </Element>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ScaledElement>
        </SwiperSlide>
        <SwiperSlide>
          <ScaledElement scaleWidth={1440} scaleHeight={754} fullWidth>
            <ScaledImage bg>
              <StaticImage src="../../images/slider-2.jpg" alt="slider" />
            </ScaledImage>
            <Container py={20}>
              <Row color="#fff">
                <Col col={{ md: 5 }}>
                  <Heading fs="display-4" color="#fff" mb={4}>
                    Keep your warehouse moving forward with us
                  </Heading>
                  <Paragraph color="#fff" mb={4}>
                    Boeing MHC thrusts to exceed clientele expectations by
                    accommodating tailor-fit solutions for every industry.
                  </Paragraph>
                  <Button variant="white" as={Link} to="/contact" size="lg">
                    <Element
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <Element as="span" mr={10}>
                        Get a free quote
                      </Element>
                      <ArrowRightIcon />
                    </Element>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ScaledElement>
        </SwiperSlide>
        <SwiperSlide>
          <ScaledElement scaleWidth={1440} scaleHeight={754} fullWidth>
            <ScaledImage bg>
              <StaticImage src="../../images/slider-3.jpg" alt="slider" />
            </ScaledImage>
            <Container py={20}>
              <Row color="#fff">
                <Col col={{ md: 5 }}>
                  <Heading fs="display-4" color="#fff" mb={4}>
                    Keep your warehouse moving forward with us
                  </Heading>
                  <Paragraph color="#fff" mb={4}>
                    Boeing MHC thrusts to exceed clientele expectations by
                    accommodating tailor-fit solutions for every industry.
                  </Paragraph>
                  <Button variant="white" as={Link} to="/contact" size="lg">
                    <Element
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      alignItems="center"
                    >
                      <Element as="span" mr={10}>
                        Get a free quote
                      </Element>
                      <ArrowRightIcon />
                    </Element>
                  </Button>
                </Col>
              </Row>
            </Container>
          </ScaledElement>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination"></div>
    </HeroStyled>
  )
}

const HeroStyled = styled.div`
  position: relative;

  .swiper-pagination {
    bottom: 12px;
    width: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
    text-align: center;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #fff;
    opacity: 0.3;
    display: block;
    margin: 0;
    cursor: pointer;
    display: inline-block;

    & + .swiper-pagination-bullet {
      margin-left: 10px;
    }
  }
  .swiper-pagination-bullet-active.swiper-pagination-bullet {
    opacity: 1;
  }

  ${media.lg`
    .swiper-pagination {
      right: 60px;
      top: 50%;
      transform: translateY(-50%);
    bottom: auto;
    width: auto;
    }

    .swiper-pagination-bullet {
        height: 50px;
        width: 6px;
    background: #fff;
    display: block;



    & + .swiper-pagination-bullet {
      margin-top: 10px;
      margin-left: 0;
    }
    }
  `}
`

export default Hero
