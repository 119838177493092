import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import AfterSalesIcon from "../Icons/AfterSalesIcon"
import AgricultureIcon from "../Icons/AgricultureIcon"
import ArrowRightIcon from "../Icons/ArrowRightIcon"
import DepartureIcon from "../Icons/DepartureIcon"
import LoadingDockIcon from "../Icons/LoadingDockIcon"
import PlumbingIcon from "../Icons/PlumbingIcon"
import SkilledInstallersIcon from "../Icons/SkilledInstallersIcon"
import TrainedTechnicianIcon from "../Icons/TrainedTechnicianIcon"
import TruckIcon from "../Icons/TruckIcon"
import ElectricIcon from "../Icons/ElectricIcon"
import Button from "../UI/Button"
import Element from "../UI/Element"
import { Col, Container, Row } from "../UI/Grid"
import ScaledElement from "../UI/ScaledElement"
import ScaledImage from "../UI/ScaledImage"
import { Section } from "../UI/Section"
import { Heading } from "../UI/Typography"
import Tower from "../../images/tower.svg"

const Services = () => {
  return (
    <SectionStyled bg="#424242">
      <Container>
        <Heading fs="display-4" mb={{ _: 10, lg: 20 }} color="#fff">
          Services
        </Heading>

        <Row>
          <Col col={{ lg: 6 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "spare-parts" }}
              position="relative"
              height="100%"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/spare-parts-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={535} scaleHeight={299}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h3" lineHeight={1}>
                    Spare
                    <br />
                    Parts
                  </Element>

                  <Element fs="h3">
                    <Row>
                      <Col col="auto">
                        <DepartureIcon />
                      </Col>
                      <Col col="auto">
                        <AgricultureIcon />
                      </Col>
                      <Col col="auto">
                        <PlumbingIcon />
                      </Col>
                      <Col col="auto">
                        <ElectricIcon />
                      </Col>
                    </Row>
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
          <Col col={{ xs: 6, lg: 3 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "trained-technicians" }}
              position="relative"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/trained-technicians-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={250} scaleHeight={302}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h5" lineHeight={1.2}>
                    Preventive Maintenance
                    <br />
                    Schedule
                  </Element>
                  <Element fs="h3">
                    <TrainedTechnicianIcon />
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
          <Col col={{ xs: 6, lg: 3 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "dock-system" }}
              position="relative"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/loading-dock-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={250} scaleHeight={302}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h5" lineHeight={1.2}>
                    Operator
                    <br />
                    Training
                  </Element>
                  <Element fs="h3">
                    <LoadingDockIcon />
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
          <Col col={{ xs: 6, lg: 3 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "skilled-installers" }}
              position="relative"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/skilled-installers-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={250} scaleHeight={302}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h5" lineHeight={1.2}>
                    Repairs
                  </Element>
                  <Element fs="h3">
                    <SkilledInstallersIcon />
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
          <Col col={{ xs: 6, lg: 3 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "after-sales" }}
              position="relative"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/after-sales-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={250} scaleHeight={302}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h5" lineHeight={1.2}>
                    After Sales
                  </Element>
                  <Element fs="h3">
                    <AfterSalesIcon />
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
          <Col col={{ lg: 6 }} mb={6}>
            <Element
              bg="var(--brand)"
              as={Link}
              display="block"
              to="/services"
              state={{ section: "rental-trucks" }}
              height="100%"
              position="relative"
            >
              <ScaledImage bg>
                <StaticImage
                  src="../../images/rental-trucks-thumb.jpg"
                  alt="spare parts"
                />
              </ScaledImage>
              <ScaledElement scaleWidth={535} scaleHeight={299}>
                <ServiceContent>
                  <Element fontWeight="bold" fs="h3" lineHeight={1}>
                    Rental and Used
                    <br />
                    Trucks
                  </Element>
                  <Element fs="h3">
                    <TruckIcon />
                  </Element>
                </ServiceContent>
              </ScaledElement>
            </Element>
          </Col>
        </Row>
        <Row mt={{ _: 10, lg: 20 }}>
          <Col col={{ _: 8, md: 6, lg: 2 }}>
            <Button
              as={Link}
              variant="white"
              size="lg"
              block="true"
              to="/contact"
              justifyContent="space-between"
            >
              <Element
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <span>Inquire Now</span>
                <ArrowRightIcon />
              </Element>
            </Button>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  )
}

const SectionStyled = styled(Section)`
  background-image: url(${Tower});
  background-repeat: no-repeat;
  background-position: right top 20px;
`

const ServiceContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: #fff;

  a:hover & {
    color: #ddd;
  }
`

export default Services
