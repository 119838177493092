import React from "react"
import Icon from "../UI/Icon"

const TrainedTechnicianIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M35 3.33325H5V33.3333H15L20 38.3333L25 33.3333H35V3.33325ZM20 8.83325C22.4833 8.83325 24.5 10.8499 24.5 13.3333C24.5 15.8166 22.4833 17.8333 20 17.8333C17.5167 17.8333 15.5 15.8166 15.5 13.3333C15.5 10.8499 17.5167 8.83325 20 8.83325ZM30 26.6666H10V25.1666C10 21.8333 16.6667 19.9999 20 19.9999C23.3333 19.9999 30 21.8333 30 25.1666V26.6666Z" />
    </Icon>
  )
}

export default TrainedTechnicianIcon
