import React from "react"
import Icon from "../UI/Icon"

const ElectricIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M35 21.6667H30V25.0001H35V21.6667Z" fill="white" />
      <path d="M20 20V23.3333H16.6667V30H20V33.3333H28.3333V20H20Z" />
      <path d="M8.33333 18.3334H20V6.66675H6.66667V10.0001H16.6667V15.0001H5V28.3334H15V25.0001H8.33333V18.3334Z" />
      <path d="M35 28.3335H30V31.6668H35V28.3335Z" />
    </Icon>
  )
}

export default ElectricIcon
