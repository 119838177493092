import React from "react"
import Icon from "../UI/Icon"

const LoadingDockIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M20 3.33325L10.8334 18.3333H29.1667L20 3.33325Z" fill="white" />
      <path d="M29.1666 36.6667C33.3088 36.6667 36.6666 33.3089 36.6666 29.1667C36.6666 25.0246 33.3088 21.6667 29.1666 21.6667C25.0245 21.6667 21.6666 25.0246 21.6666 29.1667C21.6666 33.3089 25.0245 36.6667 29.1666 36.6667Z" />
      <path d="M5 22.5H18.3333V35.8333H5V22.5Z" />
    </Icon>
  )
}

export default LoadingDockIcon
